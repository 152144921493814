<script setup>
const currentMonth = computed(() => {
  return new Date().toLocaleString('fr-FR', { month: 'long' })
})
const currentYear = computed(() => {
  return new Date().getFullYear()
})
</script>

<template>
  <div class="PromoBanner">
    <div class="PromoBanner__text">
      ⏱️ Offre spéciale {{ currentMonth }} {{ currentYear }} ⏱️
    </div>
    <div class="PromoBanner__text">
      -50% sur ta première commande
    </div>
  </div>
</template>

<style lang="scss" scoped>
.PromoBanner {
  width: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 8px;
    margin-right: 8px;
    color: white;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>