<script setup>
import useAcquisitionStore from '~/stores/acquisition';
const store = useAcquisitionStore()
const router = useRouter()
const route = useRoute()

const displayBackButton = computed(() => {
  let currentStepIndex = acquisitionSteps.findIndex(step => step.name === route.name)
  return currentStepIndex > 1
})

const displayStepper = computed(() => {
  return acquisitionSteps.find(step => step.name === route.name)?.displayStepper ?? true
})

const goBack = () => {
  let currentStepIndex = acquisitionSteps.findIndex(step => step.name === route.name)

  if (currentStepIndex > 0) {
    router.push({
      name: acquisitionSteps[currentStepIndex - 1].name
    })
    return
  }

  router.back()
}
</script>

<template>
  <div class="FunnelHeader">
    <div class="FunnelHeader__content">
      <div class="FunnelHeader__back">
        <button v-if="displayBackButton" @click="goBack" type="button" class="FunnelHeader__link" title="Retour">
          <img src="/funnel/images/back.svg" alt="" />
        </button>
      </div>
      <img src="/funnel/images/logo.svg" alt="" class="FunnelHeader__logo" />
      <div class="FunnelHeader__spacer">
      </div>
    </div>
    <div class="FunnelHeader__stepper" v-if="displayStepper">
      <FunnelStepper />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.FunnelHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 16px;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 60rem;
  }

  &__back {
    width: 22px;
  }

  &__logo {
    width: 80px;
  }

  &__spacer {
    width: 22px;
  }

  &__stepper {
    width: 100%;
    margin-top: 16px;

    @include breakpoint(lg) {
      max-width: 400px;
    }
  }
}
</style>