<script setup>
import useAcquisitionStore from '~/stores/acquisition';
import { useEventBus } from '~/utils/event-bus.js';

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Caats.co` : 'Caats.co';
  }
})

const store = useAcquisitionStore()
const router = useRouter()
const route = useRoute()
const form = ref()
const { on: eventBusOn } = useEventBus();

const onNextStep = async () => {
  let acquisitionStep = acquisitionSteps.find(step => step.name === route.name)
  console.log('acquisitionStep', acquisitionStep)
  if (acquisitionStep?.updateCat) {
    await store.updateCat(acquisitionStep.generate?.profil, acquisitionStep.generate?.menu)
  }

  if (['funnel-many'].includes(route.name)) {
    store.resetCatIndex()
    await store.updateForm()
  }

  // You submit
  if (route.name === 'funnel-you') {
    // Si 1 chat ou le chat actuel est le dernier
    if (store.cat_count === 1 || store.currentCatIndex === store.cat_count - 1) {
      await store.updateForm()
      router.push({ name: 'funnel-generate' })
      return
    } else {
      // Passer sur le prochain chat et rediger vers l'étape du nom
      await store.setNextCat() // Increment currentCatIndex + updateForm
      router.push({ name: 'funnel-name' })
      return
    }
  }

  if (store.nextStep !== null) {
    router.push({ name: `funnel-${store.nextStep}` })
  }
}

onMounted(() => {
  eventBusOn('funnel:go-to-next-step', onNextStep);
})

const nextStepText = computed(() => {
  if (isIndexStep.value) {
    return 'Démarrer'
  }

  if (store.currentStep === 'health-sickness-confirm') {
    return 'Compris'
  }

  if (store.currentStep === 'many-confirm') {
    return 'Ok, j\'ai compris !'
  }

  if (['weight-analysis-fatness-confirm', 'weight-analysis-fatness-help', 'profile-age-confirm'].includes(store.currentStep)) {
    return 'Continuer'
  }

  if (['you'].includes(store.step)) {
    if (store.countCats === 1) {
      return 'Découvrir son menu'
    }

    return 'Continuer'
  }

  return 'Suivant'
})

const isResume = computed(() => {
  return store.step === 'resume'
})

const isIndexStep = computed(() => {
  return ['profile', 'weight-analysis', 'health', 'eat-habits'].includes(store.currentStep)
})

const isYellowBackground = computed(() => {
  return ['golden-rules-reminder', 'many-confirm'].includes(store.currentStep) || isIndexStep.value
})

const isYellow200Background = computed(() => {
  return ['weight-analysis-fatness-help', 'weight-analysis-fatness-confirm'].includes(store.currentStep)
})

const isPinkBackground = computed(() => {
  return ['profile-age-confirm'].includes(store.currentStep)
})

const isBlueBackground = computed(() => {
  return [].includes(store.currentStep)
})

const displayNextButton = computed(() => {
  if (store.currentStep === 'merge-menu') {
    return false
  }

  const isStepWithButton = isIndexStep.value || [
    'funnel-many-confirm',
    'funnel-name',
    'funnel-golden-rules-reminder',
    'funnel-weight-analysis-weight',
    'funnel-profile-breed',
    'funnel-profile-age',
    'funnel-profile-age-confirm',
    'funnel-weight-analysis-fatness-confirm',
    'funnel-weight-analysis-fatness-help',
    'funnel-weight-analysis-fatness-help-advices',
    'funnel-health-sickness',
    'funnel-health-sickness-confirm',
    'funnel-health-allergies-ingredients',
    'funnel-you'
  ].includes(route.name)

  if (isStepWithButton) {
    return true
  }

  return !store.nextStepButtonDisabled
})

</script>
<template>
  <form :class="[
    'Funnel',
    isResume && 'Funnel--resume',
    isYellowBackground && 'Funnel--yellow',
    isYellow200Background && 'Funnel--yellow-200',
    isPinkBackground && 'Funnel--pink',
    isBlueBackground && 'Funnel--blue',
    isIndexStep && 'Funnel--index',
    !displayNextButton && 'Funnel--no-footer'
  ]" @submit.prevent="onNextStep" method="GET" ref="form">
    <header class="Funnel__header">
      <FunnelPromoBanner />
      <FunnelHeader />
    </header>
    <main class="Funnel__main">
      <NuxtPage />
    </main>
    <footer class="Funnel__footer" v-if="displayNextButton">
      <FunnelNextButton :disabled="store.nextStepButtonDisabled">
        {{ nextStepText }}
      </FunnelNextButton>
    </footer>
  </form>
</template>

<style>
/*.page-enter-active, .page-leave-active {
  transition: opacity 0.2s;
}

.page-enter, .page-leave-to {
  opacity: 0;
}*/
</style>

<style lang="scss" scoped>
.Funnel {
  min-height: 100svh;
  background: #f7f8f9;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  display: flex;
  $self: &;

  transition: background 0.3s;

  @include breakpoint(lg) {
    justify-content: flex-start;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__steps {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
  }

  &__step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    &__button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;

      &--completed {
        border: 2px solid lightgray;
        background: white;
      }

      &--active {
        font-weight: bold;
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 0 16px;
    padding-bottom: 116px;
    max-width: 420px;

    @include breakpoint(lg) {
      flex-grow: 0;
      padding-bottom: 0;
    }

    >* {
      width: 100%;
    }
  }

  &--resume {
    #{$self}__main {
      max-width: 500px;
    }
  }

  &--no-footer {
    #{$self}__main {
      flex-grow: 1;
      padding-bottom: 32px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 16px;
    width: 100%;
    position: fixed;
    bottom: 0;

    @include breakpoint(lg) {
      position: relative;
      max-width: 420px;
    }
  }

  &--yellow {
    background: #FFFFE8;
  }

  &--yellow-200 {
    background: #FF9;
  }

  &--pink {
    background: #FFE0E2;
  }

  &--blue {
    background: #B1E4F1;
  }
}
</style>
