export const acquisitionSteps = [
    { name: 'funnel-resume', percentage: 0, displayStepper: false, updateCat: false, generate: {} },
    { name: 'funnel-many', percentage: 0, displayStepper: false, updateCat: false, generate: {} },
    { name: 'funnel-name', percentage: 0, updateCat: true, generate: {} },
    //{ name: 'funnel-profile', percentage: 0, updateCat: false, generate:{} }, // Profile
    { name: 'funnel-profile-gender', percentage: 5, updateCat: true, generate: {} },
    { name: 'funnel-profile-age', percentage: 10, updateCat: true, generate: {} },
    { name: 'funnel-profile-neutered', percentage: 15, updateCat: true, generate: {} },
    { name: 'funnel-profile-breed', percentage: 20, updateCat: true, generate: {} },
    //{ name: 'funnel-weight-analysis', percentage: 25, updateCat: false, generate:{} }, // Weight analysis
    { name: 'funnel-weight-analysis-weight', percentage: 30, updateCat: true, generate: {} },
    { name: 'funnel-weight-analysis-fatness', percentage: 40, updateCat: true, generate: {} },
    { name: 'funnel-weight-analysis-activity', percentage: 45, updateCat: true, generate: {} },
    //{ name: 'funnel-health', percentage: 50, updateCat: false, generate:{} }, // Health
    { name: 'funnel-health-sickness', percentage: 57, updateCat: true, generate: { profil: true } },
    { name: 'funnel-health-sickness-confirm', percentage: 57, updateCat: true, generate: {} },
    { name: 'funnel-health-allergies', percentage: 63, updateCat: true, generate: {} },
    { name: 'funnel-health-allergies-ingredients', percentage: 70, updateCat: true, generate: {} },
    //{ name: 'funnel-eat-habits', percentage: 75, updateCat: false, generate:{} }, // Eat habits
    { name: 'funnel-eat-habits-dry', percentage: 80, updateCat: true, generate: {} },
    { name: 'funnel-eat-habits-dry-brand', percentage: 85, updateCat: true, generate: {} },
    { name: 'funnel-eat-habits-dry-preference', percentage: 90, updateCat: true, generate: {} },
    { name: 'funnel-eat-habits-wet', percentage: 95, updateCat: true, generate: { profil:true, menu: true } },
    { name: 'funnel-eat-habits-wet-brand', percentage: 95, updateCat: true, generate: {} },
    { name: 'funnel-you', percentage: 100, updateCat: false, generate: {} },
];