<script setup>
import useAcquisitionStore from '~/stores/acquisition';
const store = useAcquisitionStore()
const route = useRoute()

const mainStep = computed(() => {
  const routeName = route.name ?? ''

  if (routeName.startsWith('funnel-profile')) {
    return 'profile'
  }

  if (routeName.startsWith('funnel-weight-analysis')) {
    return 'weight-analysis'
  }

  if (routeName.startsWith('funnel-health')) {
    return 'health'
  }

  if (routeName.startsWith('funnel-eat-habits')) {
    return 'eat-habits'
  }

  if (routeName.startsWith('funnel-merge-menu') || routeName.startsWith('funnel-you') || routeName.startsWith('funnel-generate') || routeName.startsWith('funnel-next-cat')) {
    return 'you'
  }

  return 'profile'
})

const progressPercentage = computed(() => {
  const percentage = acquisitionSteps.find(step => step.name === route.name)?.percentage ?? 0
  return `${percentage}%`
})

const title = computed(() => {
  return {
    'profile': 'Profil du chat',
    'weight-analysis': 'Analyse du poids',
    'health': 'Santé et Allergie',
    'eat-habits': 'Habitudes alimentaires',
    'you': 'Informations',
  }[mainStep.value]
})
</script>

<template>
  <div class="FunnelStepper">
    <div class="FunnelStepper__title">
      {{ title }}
    </div>
    <div class="FunnelStepper__steps">
      <div class="FunnelStepper__progress"></div>
      <div :class="[
        'FunnelStepper__step',
        'FunnelStepper__step--active',
      ]"></div>
      <div :class="[
        'FunnelStepper__step',
        25 <= parseInt(progressPercentage, 10) && 'FunnelStepper__step--completed',
      ]">
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.15237 1.7643C7.28254 1.89448 7.28254 2.10553 7.15237 2.23571L3.4857 5.90237C3.35553 6.03255 3.14447 6.03255 3.0143 5.90237L1.34763 4.23571C1.21746 4.10553 1.21746 3.89448 1.34763 3.7643C1.47781 3.63413 1.68886 3.63413 1.81904 3.7643L3.25 5.19527L6.68096 1.7643C6.81114 1.63413 7.02219 1.63413 7.15237 1.7643Z"
            fill="white" />
        </svg>
      </div>
      <div :class="[
        'FunnelStepper__step',
        50 <= parseInt(progressPercentage, 10) && 'FunnelStepper__step--completed',
      ]">
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.15237 1.7643C7.28254 1.89448 7.28254 2.10553 7.15237 2.23571L3.4857 5.90237C3.35553 6.03255 3.14447 6.03255 3.0143 5.90237L1.34763 4.23571C1.21746 4.10553 1.21746 3.89448 1.34763 3.7643C1.47781 3.63413 1.68886 3.63413 1.81904 3.7643L3.25 5.19527L6.68096 1.7643C6.81114 1.63413 7.02219 1.63413 7.15237 1.7643Z"
            fill="white" />
        </svg>
      </div>
      <div :class="[
        'FunnelStepper__step',
        75 <= parseInt(progressPercentage, 10) && 'FunnelStepper__step--completed',
      ]">
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.15237 1.7643C7.28254 1.89448 7.28254 2.10553 7.15237 2.23571L3.4857 5.90237C3.35553 6.03255 3.14447 6.03255 3.0143 5.90237L1.34763 4.23571C1.21746 4.10553 1.21746 3.89448 1.34763 3.7643C1.47781 3.63413 1.68886 3.63413 1.81904 3.7643L3.25 5.19527L6.68096 1.7643C6.81114 1.63413 7.02219 1.63413 7.15237 1.7643Z"
            fill="white" />
        </svg>
      </div>
      <div :class="[
        'FunnelStepper__step',
        100 <= parseInt(progressPercentage, 10) && 'FunnelStepper__step--completed',
      ]">
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.15237 1.7643C7.28254 1.89448 7.28254 2.10553 7.15237 2.23571L3.4857 5.90237C3.35553 6.03255 3.14447 6.03255 3.0143 5.90237L1.34763 4.23571C1.21746 4.10553 1.21746 3.89448 1.34763 3.7643C1.47781 3.63413 1.68886 3.63413 1.81904 3.7643L3.25 5.19527L6.68096 1.7643C6.81114 1.63413 7.02219 1.63413 7.15237 1.7643Z"
            fill="white" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.FunnelStepper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-family: $primary-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: uppercase;
  }

  &__steps {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }

  &__progress {
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #DCF3F9;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      // Use progressPercentage computed
      width: v-bind('progressPercentage');
      height: 2px;
      background: #2FB7DA;
      transition: width 0.3s;
    }
  }

  &__step {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #B1E4F1;
    position: relative;
    z-index: 2;
    flex-shrink: 0;

    &--active {
      background: #2FB7DA;
    }

    &--completed {
      display: flex;
      width: 12px;
      height: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: var(--blue-400, #2FB7DA);
      margin-top: -3px;
    }
  }
}
</style>